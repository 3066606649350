import request from '@/utils/request'
import md5 from 'js-md5'

export function getUserList(keyword, skip, take) {
  return request({
    url: '/UserMgr/GetUserList',
    method: 'post',
    params: {
      keyword,
      skip,
      take
    }
  })
}

export function upsertUser(id, userName, password, type) {
  return request({
    url: '/UserMgr/UpsertUser',
    method: 'post',
    params: {
      id,
      userName,
      password: password.length === 0 ? '' : md5(password),
      type
    }
  })
}

export function deleteUser(id) {
  return request({
    url: '/UserMgr/DeleteUser',
    method: 'post',
    params: {
      id
    }
  })
}

export function getWxUserList(keyword, skip, take) {
  return request({
    url: '/UserMgr/GetWxUserList',
    method: 'post',
    params: {
      keyword,
      skip,
      take
    }
  })
}

export function getSalesmanList(keyword, skip, take) {
  return request({
    url: '/UserMgr/GetSalesmanList',
    method: 'post',
    params: {
      keyword,
      skip,
      take
    }
  })
}

export function upsertSalesman(id, name, price) {
  return request({
    url: '/UserMgr/UpsertSalesman',
    method: 'post',
    params: {
      id,
      name,
      price
    }
  })
}

export function deleteSalesman(id) {
  return request({
    url: '/UserMgr/DeleteSalesman',
    method: 'post',
    params: {
      id
    }
  })
}

export function getOperationLogList(keyword, skip, take) {
  return request({
    url: '/UserMgr/GetOperationLogList',
    method: 'post',
    params: {
      keyword,
      skip,
      take
    }
  })
}
