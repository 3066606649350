<template>
  <div class="container">
    <h1>销售员</h1>
    <div class="filter-box">
      <div class="left-box">
        <div class="item">
          <el-input v-model="params.KeyWords" size="small" placeholder="关键字" clearable />
        </div>
        <div class="item">
          <el-button type="primary" size="small" icon="el-icon-search" @click="handleFilter">搜索</el-button>
          <el-button type="default" size="small" icon="el-icon-close" @click="handleCleanFilter">清除筛选</el-button>
        </div>
      </div>
      <div class="right-box">
        <el-button v-if="user_type==='1'" type="primary" size="small" icon="el-icon-plus" @click="handleNew">新增销售员
        </el-button>
      </div>
    </div>
    <div class="table-box">
      <el-table :data="items" border style="width: 100%">
        <el-table-column prop="ID" label="ID" width="100" />
        <el-table-column prop="Name" label="销售员名称" />
        <el-table-column prop="SR_Price" label="日上券价格" />
        <el-table-column prop="scene" label="二维码" width="80">
          <template #default="scope">
            <el-popover placement="left" :width="250" trigger="hover">
              <img v-if="scope.row.ID>0" :src="getQrCodeImage(scope.row.scene)" width="250" />
              <template #reference>
                <img v-if="scope.row.ID>0" :src="getQrCodeImage(scope.row.scene)" />
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column v-if="user_type==='1'" label="操作" width="200">
          <template #default="scope">
            <el-button type="primary" size="mini" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(scope.row.ID)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin:20px auto; text-align:center">
      <el-pagination :page-size="params.Take" :total="totalCount" background layout="total, prev, pager, next"
        @current-change="handlePageChange" />
    </div>
    <edit :info="curItem" v-model:showDialog="showDialog" :refresh-parent="init" />
  </div>
</template>

<script>
  import {
    getSalesmanList,
    deleteSalesman
  } from "@/api/userMgr"

  import Edit from "./salesmanEdit"

  export default {
    components: {
      Edit
    },
    data() {
      return {
        loading: false,
        params: {
          Skip: 0,
          Take: 20,
          KeyWords: null
        },
        items: [],
        totalCount: 0,
        showDialog: false,
        curItem: {}
      }
    },
    computed: {
      user_type() {
        return this.$store.getters.user_type;
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        getSalesmanList(this.params.KeyWords, this.params.Skip, this.params.Take)
          .then(res => {
            if (res.success) {
              this.items = res.items
              this.totalCount = res.totalCount
            }
          })
      },
      getQrCodeImage(scene) {
        return process.env.VUE_APP_API_URL + '/Assert/Salesman/' + scene + '.jpg'
      },
      handleFilter() {
        this.params.Skip = 0
        this.init()
      },
      handleCleanFilter() {
        this.params.Skip = 0
        this.params.KeyWords = null
        this.init()
      },
      handlePageChange(page) {
        this.params.Skip = (page - 1) * this.params.Take
        this.init()
      },
      handleNew() {
        this.curItem = {
          ID: 0,
          Name: "",
          Price: ""
        }
        this.showDialog = true
      },
      handleEdit(row) {
        this.curItem = {
          ID: row.ID,
          Name: row.Name,
          Price: row.SR_Price
        }
        this.showDialog = true
      },
      handleDelete(id) {
        this.$confirm("确定要删除该销售员吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            this.loading = true
            deleteSalesman(id)
              .then(res => {
                if (res.success) {
                  this.$message({
                    message: "删除成功",
                    type: "success"
                  })
                  this.init()
                }
              })
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    .filter-box {
      &:after {
        content: "";
        display: table;
        clear: both;
      }

      .left-box {
        float: left;

        .item {
          float: left;
          margin-right: 10px;
        }
      }

      .right-box {
        float: right;
      }
    }

    .table-box {
      margin-top: 20px;

      img {
        width: 46px;
      }
    }
  }
</style>
